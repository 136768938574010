html {
    height: 100%;
    background: black;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text {
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    line-height: 1.43 !important;
    letter-spacing: 0.01071em !important;
}

/* title */

.Title {
    position: absolute;
    top: 0px;
    text-align: center;
    z-index: 1;
}

.Title-Label {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 12px 6px 12px;
    background: rgba(43, 51, 63, 0.7);
    user-select: none;
}

.Title-CameraLabel {
    white-space: nowrap;
    cursor: default;
    color: #fff;
}

.Title-CameraLabel-disabled {
    white-space: nowrap;
    cursor: default;
    color: gray;
}

/* live */

.live-camera-switch {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: 0.5s ease;
}

.live-view-container:hover .live-camera-switch {
    opacity: 1;
}

.fullscreen {
    display: flex;
    justify-content: center;
    position: relative;
}

.fullscreen:hover .live-camera-switch {
    opacity: 1;
}

.fullscreen:hover .Controlbar-footer {
    opacity: 1;
}

.full-opacity {
    opacity: 1;
}

/* controlbar */

.Controlbar-footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    opacity: 0;
    transition: .5s ease;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    white-space: nowrap;
}

.Controlbar-mobileFooter {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 !important;
    white-space: nowrap;
}

.Controlbar-wrapper {
    display: flex;
    align-items: center;
    background: rgba(43, 51, 63, 0.7);
    color: #fff;
}

.Controlbar-label {
    padding-left: 4px;
    padding-right: 4px;
    cursor: default;
    user-select: none;
    vertical-align: middle;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.75;
    letter-spacing: 0.02857rem;
}

.Controlbar-iconbutton {
    width: 30px;
    height: 30px;
    padding: 0;
    text-align: center;
    border: none;
    border-radius: 50%;
    outline: none;
    background: none;
    color: #fff;
    font-size: 0.1rem;
    cursor: pointer;
    vertical-align: middle;
}

.control-item {
    flex-grow: 1;
    flex-basis: 0;
}

.control-item:nth-child(3) {
    text-align: right;
}

/* archive */

.arch-mbl-height {
    height: unset !important;
}


/* timeselector */

.TimeSelector-label {
    font-weight: 500 !important; 
    font-size: 1.1rem !important;
    line-height: 1.1 !important;
    cursor: default;
    user-select: none;
}

/* timelapse */

.video-js {
    background-color: unset !important;
}

.video-js .vjs-tech {
    position: unset !important;
}

.vjs-control-bar-bottom {
    bottom: 0;
}

.vjs-full-height {
    height: 100% !important;
    width: 100% !important;
}

.vjs-quality-menu-text {
    font-size: 1.25em;
    line-height: 2.55;
    width: auto;
}

.vjs-quality-menu {
    width: 6em !important;
}

.vjs-quality-menu-item {
    width: 100% !important;
    text-transform: none !important;
}

.vjs-quality-menu .vjs-menu {
    width: 10em !important;
    left: -2em !important
}

.vjs-download-button:before {
    display: inline-block;
    background-image: url('./assets/download-arrow.svg');
    background-size: cover;
    content: '';
    color: white;
    height: 13px;
    width: 13px;
    cursor: pointer;
}

.vjs-snapshot-button:before {
    display: inline-block;
    background-image: url('./assets/camera.svg');
    background-repeat: no-repeat;
    content: '';
    height: 16px;
    width: 16px;
    cursor: pointer;
}

.vjs-error .vjs-error-display .vjs-modal-dialog-content {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

/* weather */

.Weather-boiling {
	background: #ff7675;
}

.Weather-hot {
	background: #fab1a0;
}

.Weather-warm {
	background: #fce498;
}

.Weather-perfect {
	background: #6ac3a4;
}

.Weather-cool {
	background: #67c6bb;
}

.Weather-freezing {
	background: #80bcf8;
}

/* date range picker */

.MuiDateRangePickerDay-rangeIntervalDayHighlightStart .Mui-selected {
    border: 2px solid #4caf50;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd .Mui-selected {
    border: 2px solid #ed6c02;
}

/* thin scrollbar */

#scrollableDiv {
    overflow-y: auto;
    flex-grow: 1;
}

#scrollableDiv::-webkit-scrollbar {
    width: 8px;
}

#scrollableDiv::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.26);
}

/* browsers like FF without `::-webkit-scrollbar-*` support */
@supports (not selector(::-webkit-scrollbar)) {
    #scrollableDiv {
        scrollbar-width: thin;
    }
}

/* language */

.flag {
    background-repeat: no-repeat;
    background-size: cover;
    height: 20px;
    width: 20px;
}

.flag_de {
    background-image: url("./assets/germany.png");
}

.flag_en {
    background-image: url("./assets/united-kingdom.png");
}

.flag_nl {
    background-image: url("./assets/netherlands.png");
}

@media (max-width: 900px) {
    .height {
        height: unset !important;
    } 
    .height-2 {
        height: calc((100vh - 64px)/2) !important;
    }
}

@media (max-width: 600px) {
    .height-2 {
        height: calc((100vh - 48px)/2) !important;
    }
}